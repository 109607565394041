<template>
  <div class="home">
    <div class="address_div">
      <svg-icon icon-class="location" class="location"></svg-icon>
      <span class="mapAddressText" @click="pushRouter('/selectAddress')">{{mapAddress}}</span>
      <!-- <span class="mapAddressText" @click="$router.push({ path: '/selectAddress' })">{{mapAddress}}</span> -->
      <van-icon name="arrow-down" />
    </div>
    <van-swipe :autoplay="3000">
      <van-swipe-item
        v-for="(item, index) in bannerList"
        :key="index"
        @click="bannerClick(item)"
      >
        <img v-lazy="item.bannerImageUrl" class="bannerImg" />
      </van-swipe-item>
    </van-swipe>
    <div class="authentication">
      <p>
        <svg-icon icon-class="medal" class="cardIcon"></svg-icon
        ><span>官方认证</span>
      </p>
      <p>
        <svg-icon icon-class="card" class="cardIcon"></svg-icon>
        <span>爽约包退</span>
      </p>
      <p>
        <svg-icon icon-class="identify" class="cardIcon"></svg-icon>
        <span>信息真实</span>
      </p>
      <p>
        <svg-icon icon-class="guard" class="cardIcon"></svg-icon>
        <span>全场保障</span>
      </p>
    </div>
    <div class="other">
      <p @click="pushRouter('/first-order-benefits')">
        <svg-icon icon-class="ticket" class="otherIcon"></svg-icon>
        <span>领券中心</span>
      </p>
      <p @click="pushRouter('/vipCenter')">
        <svg-icon icon-class="vip" class="otherIcon"></svg-icon>
        <span>会员中心</span>
      </p>
      <p @click="$router.push({ path: '/recruit' })">
        <svg-icon icon-class="cooper" class="otherIcon"></svg-icon>
        <span>招聘/合作</span>
      </p>
      <p @click="recruitNew">
        <svg-icon icon-class="icon_invite_new" class="otherIcon"></svg-icon>
        <span>拉新赚钱</span>
      </p>
    </div>
    <div class="recommend">
      <p class="div-title">
        <span class="title">金牌推荐</span>
        <span @click="$router.push({ path: '/service' })"
          >更多技师 <van-icon name="arrow"
        /></span>
      </p>
      <div class="recommendList">
        <template v-if="artificerList.length == 0">
          <p style="text-align: center;width: 100%;color: #ccc;padding: .2rem 0;">-- 暂无数据 --</p>
        </template>
        <p v-for="item in artificerList" :key="item.artificerId" @click="$router.push({ path:'/attendantDetails', query: { id: item.id } });" class="artificer-item">
          <img :src="item.avatar" alt="" />
          <span class="name">{{ item.nickName }}</span>
          <span class="num">关注{{ item.repeatCustomer >99?'99+':item.repeatCustomer }}</span>
        </p>
      </div>
    </div>
    <div class="project">
      <p class="div-title">
        <span class="title">服务项目</span>
      </p>
      <div class="project-div">
        <template v-if="serveList.length == 0">
          <p style="text-align: center;width: 100%;color: #ccc;padding: .2rem 0;">-- 暂无数据 --</p>
        </template>
        <ProjectList :serveList="serveList" />
      </div>
    </div>
    <div class="copyright">
      <p>Copyright@北京蓝仕蒂都科技有限公司</p>
      <p>京ICP备2023008635号</p>
    </div>
  </div>
</template>
<script type="text/javascript">
import AMapLoader from "@amap/amap-jsapi-loader";
import {
  getLsHomeBannerListOut,
  getLsHomeArtificerListOut,
  getLsHomeServeListOut,
  editLsPromotionChannelEnterData
} from "@/api/index";
import { screenArtificer, getMapArtificerList } from '@/api/service'
import Location from "@/utils/location";
import ProjectList from "@/components/projectList.vue";
import { wxConfig } from "../utils/index";
import wx from "weixin-js-sdk"
export default {
  components: {
    ProjectList
  },
  data() {
    return {
      routerPage: "/reasfasfafasf",
      city_name: "",
      city_code: "",
      bannerList: [],
      artificerList: [],
      serveList: [],
      mapAddress: "定位中...",
      lng: 116.397455,
      lat: 39.919087,
    };
  },
  created() {
    if (this.$route.query.channelCode) {
      localStorage.setItem("channelCode", this.$route.query.channelCode);
      this.editLsPromotionChannelEnterData();
    }

    const selectCityAddress = JSON.parse(sessionStorage.getItem('selectCityAddress'))
    if (selectCityAddress) {
        // sessionStorage.removeItem('selectCityAddress')
      this.mapAddress = selectCityAddress.cityInfo.detailAddress 
      this.lat = selectCityAddress.cityInfo.lat
      this.lng = selectCityAddress.cityInfo.lng
      this.getLsHomeArtificerListOut();
    }else{
      this.setMap();
    }
    this.getPageData();
  },
  methods: {
    jumpAddress() {

      this.$router.push({
        path: "/selectAddress"
      });
    },
    // 获取页面数据
    getPageData() {
      this.getLsHomeBannerListOut();
      this.getLsHomeServeListOut();
    },
    // 获取banner
    getLsHomeBannerListOut() {
      getLsHomeBannerListOut().then(res => {
        this.bannerList = res.data;
      });
    },
    // 获取首页推荐数据
    getLsHomeArtificerListOut() {
      // getLsHomeArtificerListOut().then(res => {
      //   this.artificerList = res.data;
      // });
      let params = {
        pageIndex: 1,
        pageSize: 10,
        lng: this.lng,
        lat: this.lat,
        artificerName: ""
      };

      screenArtificer(params)
        .then(res => {
          if (res.code == 10000) {
            this.artificerList = this.artificerList.concat(res.data.records);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取服务项目
    getLsHomeServeListOut() {
      const cityCode = localStorage.getItem("cityCode") || "110100"
      getLsHomeServeListOut({
        cityCode: cityCode
      }).then(res => {
        this.serveList = res.data;
      });
    },
    pushRouter(routerUrl) {
      if(routerUrl == "/selectAddress"){
        let _this = this
        if(this.mapAddress == "定位获取失败"){
          var ua = navigator.userAgent.toLowerCase();
          if (ua.match(/MicroMessenger/i) == "micromessenger") {

            _this.$store.commit("loadingControl",true)
            wx.getLocation({
              type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
              success: function (res) {
                console.log('---')
                _this.setMap();
              },
              fail:function(err){
                console.log(err)
                _this.$toast("定位失败，请检查网络或GPS是否正常开启")
              },
              complete:()=>{
                _this.$store.commit("loadingControl",false)
              }
            });
          } else {
            this.mapAddress = "定位中..."
            this.setMap();
          }
        }else if(this.getwxcode(routerUrl) && this.mapAddress != "定位中..."){
          this.$router.push({
              path: routerUrl
            });
        }
      }else {
        if(this.getwxcode(routerUrl)){
          this.$router.push({
              path: routerUrl
            });
        }
      }
    },

    // 点击去邀新
    recruitNew() {
      // if (this.getwxcode("/recruit-new")) {
      //   this.$router.push({ path: "/recruit-new" });
      // }
      // this.$toast("敬请期待~");
      
      if (this.getwxcode("/invite")) {
        this.$router.push({ path: "/invite" });
      }
    },
    // banner点击
    bannerClick(item) {
      let jumpUrl = item.jumpUrl;
      console.log(jumpUrl)
      if(jumpUrl == ""){
        return;
      }
      let pushPath, pushQuery;
      if (jumpUrl.indexOf("service") == -1) {
        pushPath = "/recruit";
      } else {
        (pushPath = "/projectDetails"),
          (pushQuery = {
            id: jumpUrl.split(":")[1]
          });
      }
      this.$router.push({
        path: pushPath,
        query: pushQuery
      });
    },
    // 获取定位
    setMap() {
      let _this = this;
     
     try {
      AMapLoader.load({
        plugins: ["AMap.Geolocation"]
      }).then(AMap => {
          Location.getCurrentLocation(
            AMap,
            result => {
              _this.$store.commit("loadingControl",false)
              _this.getPageData();
              _this.mapAddress = result.pois[0].name || result.addressComponent.building;
              _this.lng = result.position.lng
              _this.lat = result.position.lat
              this.getLsHomeArtificerListOut();
            },
            () => {
              this.mapAddress = "定位获取失败";
              this.$store.commit("loadingControl",false)
              this.$toast("定位失败，请检查网络或GPS是否正常开启")
              this.getLsHomeArtificerListOut();
            },
            true
          );
        })
        .catch(e => {
          console.log('------调用定位失败-------')
          console.log(e);
        });
     } catch (error) {
      console.log(error)
     }
    },
    // 推广渠道
    editLsPromotionChannelEnterData() {
      editLsPromotionChannelEnterData({
        channelCode: this.$route.query.channelCode
      }).then(res => {});
    }
  }
};
</script>

<style lang="less" scoped>
.home {
  min-height: calc(100vh - 50px);
  // padding: 0 0.2rem;
  padding-bottom: 50px;
  background-color: #eef2f3;
  .van-swipe {
    width: 100%;
    height: 3.95rem;
  }
  .location {
    width: 0.36rem;
    height: 0.36rem;
  }
  .address_div {
    padding: 0.4rem .2rem;
    display: flex;
    align-items: center;
    background-color: #fff;
  }
  .bannerImg {
    width: 100%;
    height: auto;
    border-radius: 0.2rem;
  }
  .mapAddressText {
    // display: inline-block;
    // width: 3rem;
    max-width: 3rem;
    margin: 0 0.1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .authentication {
    display: flex;
    justify-content: space-around;
    margin: 15px 0;
    // background-color: #fff;
    padding: 0 .2rem;
    p {
      flex: 1;
      background: #000;
      padding: 0.3rem;
      color: #d9b77a;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.26rem;
      border-radius: 0.1rem;
      &:not(:first-child) {
        margin-left: 0.2rem;
      }
      .cardIcon {
        margin-right: 5px;
      }
    }
  }
  .other {
    display: flex;
    justify-content: space-around;
    margin: 0.3rem 0rem;
    p {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: .5rem;
      background-color: #fff;
      padding: 0.2rem;
      font-size: 0.26rem;
      // color: #7e7d7d;
      .otherIcon {
        width: 1.5rem;
        height: 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
  .recommend{
    padding: 0 .2rem;
    // background-color: #fff;
  }
  .div-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5rem 0;
    .title {
      font-size: 16px;
      font-weight: bold;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    }
  }
  .recommendList {
    display: flex;
    max-width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    margin-bottom: 0.3rem;
    .artificer-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: .1rem 0;
      border-radius: .2rem;
      &:not(:first-child) {
        margin-left: 0.3rem;
      }
      .name {
        font-size: 0.36rem;
        margin: 0.1rem 0;
      }
      .num {
        color: #7e7d7d;
      }
      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0.2rem;
      }
    }
  }
  .project{
    // padding: 0 .2rem;
    .title{
      padding: 0 .2rem;
    }
  }
  .projectList {
    display: flex;
    margin-bottom: 20px;
    .projectImg {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.2rem;
      margin-right: 0.4rem;
    }
    .information {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name {
        font-size: 16px;
        font-weight: bold;
      }
      .vip-information {
        display: flex;
        align-items: center;
      }
      .money {
        font-size: 0.36rem;
        font-weight: 600;
        color: orange;
        width: 1.3rem;
        .moneyIcon {
          font-size: 0.24rem;
        }
      }
      .vipSpan {
        display: flex;
        align-items: center;
        font-size: 0.28rem;
        background: rgb(248, 188, 78);
        border-radius: 0.4rem;
        width: 2.6rem;
        .vipIcon {
          height: 0.5rem;
        }
      }
      .timeIcon {
        font-size: 0.36rem;
        margin-right: 3px;
      }
      .synopsis {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.24rem;
        color: rgb(133, 133, 133);
      }
    }
  }
  .copyright{
    font-size: .3rem;
    color: #8e9eab;
    text-align: center;
    line-height: .6rem;
    padding: .5rem 0;
  }
}
</style>

<template>
  <div>
    <div class="project-div">
      <div
        v-for="item in serveList"
        :key="item.serveId"
        class="projectList"
        @click="
          $router.push({ path: '/projectDetails', query: { id: item.serveId } })
        "
      >
        <img class="projectImg" :src="item.serveImgUrl" alt="" />
        <div class="information">
          <p class="name">{{ item.serveName }}</p>
          <p class="vip-information">
            <span class="money">
              <font class="moneyIcon">￥</font>{{ item.servePrice }}
            </span>
            <span class="vipSpan"
              ><img
                src="@/assets/img/vip_icon.png"
                alt=""
                class="vipIcon"
              /><span>￥{{ item.serveVipPrice }}</span></span
            >
          </p>
          <p class="vip-information">
            <van-icon name="clock-o" class="timeIcon" /><span
              >{{ item.serveTime }}分钟</span
            >
          </p>
          <div class="synopsis-operation">
            <p class="synopsis">{{ item.serveIntroduce }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serveList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {};
  },
  components: {}
};
</script>

<style lang="less" scoped>
@import "../styles/base.less";
.project-div {
  
  .projectList {
    display: flex;
    background-color: #fff;
    margin-bottom: .2rem;
    padding: .25rem;
    border-radius: .2rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    .projectImg {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 0.2rem;
      margin-right: 0.4rem;
    }

    .information {
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .name {
        font-size: 16px;
        font-weight: bold;
      }

      .vip-information {
        display: flex;
        align-items: center;
      }

      .money {
        font-size: 0.36rem;
        font-weight: 600;
        color: orange;
        width: 1.3rem;

        .moneyIcon {
          font-size: 0.24rem;
        }
      }

      .vipSpan {
        display: flex;
        align-items: center;
        font-size: 0.28rem;
        background: rgb(248, 188, 78);
        border-radius: 0.4rem;
        width: 2.7rem;

        .vipIcon {
          height: 0.5rem;
        }
      }

      .timeIcon {
        font-size: 0.36rem;
        margin-right: 3px;
      }
      .synopsis-operation {
        .flex-row-between();
        align-items: flex-end;
        .synopsis {
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 0.24rem;
          color: rgb(133, 133, 133);
        }
        .operationBtn {
          width: 1.5rem;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
</style>
